.wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.wheel {
  width: 365px;
  height: 365px;
  position: relative;
  border-radius: 50%;
  border: 10px solid gold;
  cursor: pointer;
}

.center-arrow {
  position: absolute;
  top: 48%;
  left: 50%;
  width: 45px; /* Изменить по необходимости */
  height: 45px; /* Изменить по необходимости */
  transform: translate(-50%, -50%);
  z-index: 10; /* Убедитесь, что оно выше колеса */
}
.center-circle {
  position: absolute;
  top: 50%;   
  left: 50%;
  width: 100px; /* Изменить по необходимости */
  height: 100px; /* Изменить по необходимости */
  transform: translate(-50%, -50%);
  z-index: 9; /* Убедитесь, что оно выше колеса */
}

.spin-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #FF7F50;
  color: white;
  border: none;
  cursor: pointer;
}

.result-text {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #FF7F50;
}
