/* CoinDisplay.css */
.coin-display {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .coin {
    display: flex;
    align-items: center;
    margin-right: 0px;
  }
  
  .coin-icon {
    width: 33px;
    height: 33px;
    margin-right: 3px;
  }
  
  .coin-amount {
    font-size: 16px;
    font-weight: bold;
  }
  .coin-display {
    display: flex;
    justify-content: center; /* Центрируем монетки по горизонтали */
    gap: 12px; /* Расстояние между монетками */
  }
  
  .coin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background-color: #f0f0f0; /* Светло-серый фон овала */
    border-radius: 50px; /* Овальная форма */
  }

  
  .coin-value {
    font-size: 16px;
    font-weight: bold;
    color: #000; /* Черный цвет текста */
  }
  
  .coin-icon {
    filter: grayscale(80%); /* Делает иконки серыми */
  }
  