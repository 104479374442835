.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup {
    background: #fff;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.3s ease;
    margin-bottom: 30px; /* Увеличенный отступ снизу */
  }
  
  .popup-icon {
    width: 120px;
    
  }
  
  .popup h2 {
    font-size: 1.5em;
    color: #333;
  }
  
  .popup-points {
    font-size: 1.2em;
    color: #ff6f00;
    margin: 10px 0;
    font-weight: bold;
    text-shadow: 1px 1px #ffcc99;
  }
  
  .popup-button {
    background-color: #ff6f00;
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 80%;
  }
  
  .popup-button:hover {
    background-color: #e65c00;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  