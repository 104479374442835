.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.footer-container {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 10px 15px;
    gap: 10px;
    position: relative;
}

.back-btn {
    position: absolute;
    left: -100px; /* Смещение влево на 50px от футер-контейнера */
    background-color: #f0f0f0;
    border-radius: 33px;
  
    padding: 14px 15px;
    gap: 10px;
    border: none;
    cursor: pointer;
    margin-right: 50px;
}

.back-btn img {
    width: 12px;
    height: 12px;
}

.footer-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    border: none;
    cursor: pointer;
}

.footer-btn img {
    width: 24px;
    height: 24px;
}

.spin-btn {
    background-color: #ff7f50;
    color: #fff;
    border-radius: 25px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: none;
    margin-left: 8px;
}

.spin-btn img {
    width: 50px;
    height: 50px;
    margin-top: -2px;
    margin-bottom: -2px;
}

.spin-text {
    margin-left: 8px;
}

.spin-count {
    background-color: rgba(255, 255, 255, 0.8);
    color: #ff7f50;
    border-radius: 10px;
    padding: 3px 6px;
    font-size: 0.8em;
    margin-left: -5px;
}
