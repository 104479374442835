.friends {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
}

.friends h2 {
    font-size: 1.3em;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.friends p {
    color: #333;
    font-size: 0.85em;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.bonus-cards {
    display: flex;
    gap: 15px;
    margin: 20px 0;
}

.card {
    background-color: #f9f9f9;
    padding: 15px 20px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    width: 120px;
}

.friends-card {
    background-color: #f5f5f5;
    text-align: left;
}

.premium-friends-card {
    
    background: linear-gradient(135deg, #FF6200, #9987FF);
    color: #fff;
}
.premium-friends-card p{
    color: #fff;
}

.card p {
    text-align: left;
    font-weight: bold;
    font-size: 0.9em;
    margin: 5px 0;
}

.bonus-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
}
.bonus-icons span{
    font-weight: bold;
    margin-top: -5px;
    margin-left: -10px;
}

.bonus-icon {
    width: 25px;
    height: 25px;
}

.spin-icon{
    width: 33px;
    height: 33px;
}

.invite-section {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 320px;
}

.invite-link {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f0f0f0;
    padding: 12px 15px;
    border-radius: 20px;
    flex: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.invite-link input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 0.85em;
    color: #333;
    background-color: transparent;
    font-weight: bold;
}

.invite-link button {
    background: none;
    border: none;
    cursor: pointer;
}

.share-btn img,
.copy-btn img {
    width: 18px;
    height: 18px;
}

.copy-btn {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border: none;
}

.friends-stats-combined {
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    text-align: left;
}

.stats-item p {
    margin: 0;
    font-weight: bold;
    font-size: 0.9em;
}

.stats-item span {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 8px;
    display: block;
}

.stats-item:nth-child(2) p {
    background: linear-gradient(135deg, #FF6200, #9987FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
