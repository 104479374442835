/* Header.css */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    margin-top: 10px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .username {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    padding: 10px;
    
  }
  
  .balance-info {
    display: flex;
    align-items: center;
  }
  
  .balance-item {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  
  .balance-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  
  .notifications {
    position: relative;
  }
  
  .bell-icon {
    width: 20px;
    height: 20px;
  }
  
  .header-button {
    background-color: #f0f0f5;
    border-radius: 16px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    background-color: white;
  }
  .header-item {
    margin: 0 10px; /* Отступы между элементами */
  }
  
  .profile-section {
    display: flex;
    align-items: center;
  }
  
  .profile-icon {
    filter: grayscale(100%); /* Делает иконки серыми */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .profile-name {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    margin-right: 30px;
    
  }
  
  .buttons-section {
    display: flex;
    gap: 10px;
  }
  
  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;  /* Ширина овала */
    height: 40px;  /* Высота овала */
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;  /* Полный радиус для овальной формы */
    margin: 0 px;
    cursor: pointer;
  }
  
  .header-icon {
    width: 25px;
    height: 25px;
    filter: grayscale(80%); /* Делает иконки серыми */
  }
  