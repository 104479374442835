.invite-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;
    max-width: 333px;
    margin-top: 20px;
    border: 3px solid transparent;
    background-clip: padding-box;
    position: relative;
}

.invite-button::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: 0px;
    bottom: 0px;
    border-radius: 22px;
    background: linear-gradient(45deg, #ff7f50, #ff00ff);
    z-index: -1;
}

.invite-text {
    font-weight: bold;
    font-size: 1em;
    text-align: left;
    max-width: 70%; /* Ограничиваем ширину текста до 70% от ширины кнопки */
    word-wrap: break-word; /* Переносим текст по словам */
    line-height: 1.2em;
    background: linear-gradient(45deg, #ff7f50, #ff00ff); /* Градиент для текста */
    -webkit-background-clip: text;
    color: transparent;
}

.invite-arrow {
    width: 12px;
    height: 12px;
    margin-left: 10px;
}
