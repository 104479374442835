.quest-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f0f0f0;
    border-radius: 20px;

    width: 100%;
    max-width: 350px;
  }
  
  .quest-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;

  }
  
  .quest-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
  }

  
  .quest-title {
    text-align: left; /* Выровнять текст внутри */
    font-weight: bold;
    font-size: 1em;
    flex: 1;
    padding-left: 0px;
    margin-left: 10px; 
    color: #333;
  }
  
  .quest-status {
    font-size: 0.9em;
    color: #ff7f50;
  }
  
  .quest-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px; /* Внутренний отступ сверху и снизу */
  }
  
  .quest-points {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1em;
    font-weight: bold;
  }
  
  .quest-points span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1; /* Убираем дополнительное пространство вокруг текста */
    position: relative;
    top: -2px; /* Поднимаем текст чуть выше */
  }
 
  
  .quest-points img {
    width: 30px;
    height: 30px;
  }
  
  .quest-action {
    background-color: #ff7f50;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 8px 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8em;

  }
  
  .quest-item {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
     border-radius: 25px 25px 25px 25px; /* Закругление левых углов */
  }
  
  .quest-top {
    display: flex;
    align-items: center;
  }
  
  .quest-icon-gradient {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px 0 0 0px; /* Закругление левых углов */
    margin-right: 10px;
  }
  
  .icon-image {
    width: 30px; /* Размер иконки */
    height: auto;
  }
  
  /* Градиенты для каждого типа */
  .telegram-gradient {
    background: linear-gradient(270deg, #f0f0f0 0%, #00C6FF 100%);
  }
  
  .discord-gradient {
    background: linear-gradient(270deg, #f0f0f0 0%, #7289DA 100%);
  }
  
  .x-gradient {
    background: linear-gradient(270deg, #f0f0f0 0%, #000000 100%);
  }
  
  .medium-gradient {
    background: linear-gradient(270deg, #f0f0f0 0%, #FFFACD 100%);
  }
  
  .youtube-gradient {
    background: linear-gradient(270deg, #f0f0f0 0%, #FF6347 100%);
  }
  
  /* Стили для текста */
  .quest-title {
    font-weight: bold;
    font-size: 16px;
  }
  