.quests {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; /* Убираем отступы */
    margin-top: 20px; /* Добавляем отступ сверху, чтобы квесты не были слишком близко к заголовку */
    box-sizing: border-box;
}
.quests h2{
    color: #333;
    font-size: 1.3em;
 }
 
.quest-list {
    max-height: 70vh; /* Устанавливаем фиксированную высоту для списка */
    overflow-y: auto; /* Добавляем вертикальную прокрутку */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px; /* Расстояние между заданиями */
    padding: 0 10px; /* Убираем вертикальные отступы */
    box-sizing: border-box;

    align-items: center;
}

.quest-list::-webkit-scrollbar {
    width: 8px;
}

.quest-list::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
}

